var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-field',[_c('BaseInput',{staticClass:"w-100",attrs:{"placeholder":"Search assets by name...","type":"search","lazy":true,"value":_vm.search,"size":"large"},on:{"input":function($event){_vm.search = $event;
        _vm.handleLoadData();}}}),_c('BaseButton',{staticClass:"mx-1",attrs:{"action":"principal","size":"large"},on:{"click":_vm.handleLoadData}},[_c('i',{attrs:{"slot":"icon"},slot:"icon"},[_c('IconSearch')],1)])],1),(!_vm.isLoading)?_c('GuideBox',{attrs:{"title":"Editing your itinerary","description":"Let´s add this one","step":42,"positionX":-10,"positionY":200,"showNextButton":false}}):_vm._e(),_c('GuideBox',{attrs:{"title":"Editing your itinerary","description":"Let´s add this one","step":54,"positionX":-10,"positionY":200,"showNextButton":false}}),_c('div',{attrs:{"id":"gallery","data-test-id":_vm.constants.DATA_TEST_ID_VALUES.PHOTOS_GALLERY}},_vm._l((_vm.data.data),function(image,index){return _c('div',{key:index,class:_vm.focusedGuideBoxClass('', [42, 54], false, function () { return index === 0; })},[_c('expandable-image',{staticClass:"gallery_img",attrs:{"src":_vm.getAssetImage(image),"alt":image.name,"title":image.name,"close-on-background-click":true}}),_c('div',{staticClass:"is-flex is-align-content-space-between buttons-img"},[_c('b-checkbox',{staticClass:"checkAdd",attrs:{"type":"is-success","value":_vm.assetsList
              ? _vm.assetsList.findIndex(function (list) {
                  return list.id == image.id;
                }) >= 0
                ? true
                : false
              : false},on:{"input":function($event){_vm.nextStep();
            _vm.handleAssetOnClick($event, image);}}},[_vm._v("Add")])],1),_c('p',{staticClass:"description"},[_c('span',[_vm._v(" Name:"),_c('b',[_vm._v(" "+_vm._s(_vm._f("truncate")(image.name,15, "...")))])]),(_vm.isLocationList())?_c('span',[_c('br'),_vm._v(" Location:"),_c('b',[_vm._v(" "+_vm._s(image.path))])]):_vm._e()])],1)}),0),_c('b-pagination',{staticClass:"mt-6",attrs:{"total":_vm.data.total,"range-before":1,"range-after":2,"rounded":false,"size":"is-small","per-page":10,"order":"is-centered"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('b-pagination-button',{attrs:{"page":props.page,"tag":"button","id":("page" + (props.page.number))},nativeOn:{"click":function($event){return _vm.onPageChange(props.page.number, props)}}},[_vm._v(" "+_vm._s(props.page.number)+" ")])]}},{key:"previous",fn:function(props){return [_c('b-pagination-button',{attrs:{"page":props.page},nativeOn:{"click":function($event){return _vm.onPageChange(props.page.number)}}},[_c('IconArrowLeft',{staticClass:"icon_previous"})],1)]}},{key:"next",fn:function(props){return [_c('b-pagination-button',{attrs:{"page":props.page},nativeOn:{"click":function($event){return _vm.onPageChange(props.page.number)}}},[_c('IconArrowRight',{staticClass:"icon_next"})],1)]}}]),model:{value:(_vm.data.currentPage),callback:function ($$v) {_vm.$set(_vm.data, "currentPage", $$v)},expression:"data.currentPage"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }